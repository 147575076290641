import React from 'react'
import TopNav from './NavbarComponent'

function How() {
    return (
        <>
            <TopNav />
            <div style={{height: 75}}></div>
            <div className='howText' style={{width: '50%', margin: 'auto'}}>
                <p>How to JeckIt</p>
            </div>
        </>
    )
}

export default How;