import React, { Component } from 'react';
import TopNav from './NavbarComponent';
import Article from './ArticleComponent';
import axios from 'axios';
import Search from './SearchComponent';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const searchApi = 'https://jeckit-microservices-win.azurewebsites.net/api/SearchArticles?code=Fr0N6APM3HS8/bo1pUuOX7oFabNhbZlbiOGLGPKkfRfwSyLolKEpZg==&limit=10&offset=0&search='

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            offset: 0,
            searching: false,
            keyword: ''
        }
    }

    getSearchResults = (e) => {
        window.scrollTo(0,0)
        let searchKeyword = document.getElementById('searchInput').value
        const finalUrl = searchApi + searchKeyword

        axios.get(finalUrl)
            .then(res => {
                this.setState(response => ({data: res.data, offset: 0, searching: true, keyword: searchKeyword}))
                
                document.title = 'Jeckit - Search: ' + searchKeyword;
            })
    }

    handleClick = (e) => {
        e.preventDefault()
        this.getSearchResults(e)   
    }

    getMoreData = () => {
        let loadMoreApi = '';
        if (this.state.searching === false) {
            loadMoreApi = `https://jeckit-microservices-win.azurewebsites.net/api/GetArticleList?code=itRQTu5HWpAVdovyTPIlOSqmXKXxf880o8tRjc9vCZtdloRYladFlg==&limit=10&offset=${this.state.offset}`
        } else {
            loadMoreApi = `https://jeckit-microservices-win.azurewebsites.net/api/SearchArticles?code=Fr0N6APM3HS8/bo1pUuOX7oFabNhbZlbiOGLGPKkfRfwSyLolKEpZg==&limit=10&offset=${this.state.offset}&search=${this.state.keyword}`
        }
        
        axios.get(loadMoreApi)
        .then(res => {
            const articleData = res.data;
            this.setState(response => ({data: this.state.data.concat(articleData), offset: this.state.offset + 10}))
        })
       
    }

    getData = () => {
        axios.get('https://jeckit-microservices-win.azurewebsites.net/api/GetArticleList?code=itRQTu5HWpAVdovyTPIlOSqmXKXxf880o8tRjc9vCZtdloRYladFlg==&limit=10&offset=0')
        .then(res => {
            const articleData = res.data;
            this.setState(response => ({data: articleData, searching: false, offset: this.state.offset + 10}))
        })
    }

    componentDidMount() {
        this.getData()
    }

    render() {
        return (
            <>
            <div style={{paddingBottom: 25}}>
                <TopNav />
                <div style={{height: 75}}></div>
                <Search onClick={this.handleClick} />
                <InfiniteScroll dataLength={this.state.data.length} next={this.getMoreData} hasMore={true} 
                    loader={<Box sx={{ display: 'flex' }}><CircularProgress color='inherit' style={{ margin: 'auto' }} /></Box>}>
                    {this.state.data.map(article => <Article key={article.id} {...article} />)}
                </InfiniteScroll>
            </div>
            </>
        )
    }
}

export default Main
