import React, { Component} from 'react';
import TopNav from './NavbarComponent';
import axios from 'axios';
import BuildCard from './BuildCardComponent';

const rawUrl = window.location.pathname;
const finalUrl = rawUrl.slice(9)
const api = 'https://jeckit-microservices-win.azurewebsites.net/api/GetArticle?code=RjKlJmPas7oaQ955QH0At4HU1WhB/qwLFKzJtiEUKtCIcaRxaWe1QQ==&url='
const full = api + finalUrl;

class ArticleDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.location.article
        }
    }

    getData = () => {
        axios.get(full)
        .then(res => {
           this.setState({data: res.data})
        })
    }

    componentDidMount = () => {
        window.scrollTo(0,0)
        this.state.data === undefined ? this.getData() : console.log('');
        // console.log(this.props)
    }

    render() {
        return (
            <>
                <TopNav />
                <div style={{height: 75}}></div>
                {this.state.data !== undefined ? <BuildCard {...this.state.data} /> : <div></div>}
            </>
        )
    }
}

export default ArticleDetail;