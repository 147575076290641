import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import { Link } from '@mui/material';

function HomeIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      </SvgIcon>
    );
  }

function WhyIcon() {
    return (
        <SvgIcon style={{width:'24px', height:'24px', marginTop:'-5px', marginRight:'5px'}} viewBox="0 0 24 24">
            <path fill="currentColor" d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" />
        </SvgIcon>
    )
}

function HowIcon() {
    return (
        <SvgIcon style={{width:'24px', height:'24px', marginTop:'-5px', marginRight:'5px'}} viewBox="0 0 24 24">
            <path fill="currentColor" d="M5,21A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19C21,20.11 20.1,21 19,21H5M6,13H14.5L11,16.5L12.42,17.92L18.34,12L12.42,6.08L11,7.5L14.5,11H6V13Z" />
        </SvgIcon>
    )
}

export default function TopNav() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar style={{background: '#a93b44', zIndex: '100'}}  position="fixed">
        <Toolbar style={{margin: 'auto'}}>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}

          <Link id='navLink' href='/' underline='none' variant="h6" component='a' color='black' sx={{ flexGrow: 1}} style={{marginLeft: 32}}><HomeIcon style={{marginTop: -5, marginRight: 5}} />JeckIt</Link>
          <Link id='navLink' href='/Why' underline='none' variant="h6" component='a' color='black' sx={{ flexGrow: 1}} style={{marginLeft: 32}}><WhyIcon style={{marginTop: -5, marginRight: 5}} />Why</Link>
          <Link id='navLink' href='/About' underline='none' variant="h6" component='a' color='black' sx={{ flexGrow: 1}} style={{marginLeft: 32}}><HowIcon style={{marginTop: -5, marginRight: 5}} />About</Link>
          <Typography id='blurb' variant="h6" component="div" sx={{ flexGrow: 1 }} style={{marginLeft: 32}}>We just thought you'd wanna know</Typography>

        </Toolbar>
      </AppBar>
    </Box>
  );
}