import React, { Component } from 'react'
import { Fab } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';

export default function MobileSearch() {
    let toggle = false;

    const showSearch = () => {
        if (toggle == false) {
            document.getElementById('searchBar').style.visibility = 'visible'
            toggle = true
        } else {
            document.getElementById('searchBar').style.visibility = 'hidden'
            toggle = false
        }
    }

    return (
        <Fab id='searchIcon' onClick={showSearch}>
            <SearchIcon />
        </Fab>
    )
}