import Badge from '@mui/material/Badge'; 
import { Link } from 'react-router-dom';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { Tooltip } from '@mui/material';
import * as React from 'react';
// import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
// import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MyButton } from './SearchComponent';
import { FacebookShareButton, FacebookIcon, RedditShareButton, RedditIcon, EmailShareButton, EmailIcon } from 'react-share';

export const formattedDateAndTime = (date) => {
  return new Date(date).toLocaleString();
}

// const languageDetect = require('languagedetect');
// const dectector = new languageDetect();

export default function Article(props) {

  const newTo = { 
    pathname: "/Article/" + props.url,
    article: props 
  };

  const shareUrl = window.location + "/Article/" + props.url

  // const lang = dectector.detect(props.text, 1)

  const getCardBody = (props) => {
    const cardBody = props.text
    if (cardBody.length > 1000) {
      const newCardBody = cardBody.slice(0, 1000) + ' . . . ';
      return newCardBody
    } 
    else {
      return cardBody;
    }
  }

  const getSourceLogo = (source) => {
    const rough = source.replace('www.', '').replace('.com', '')
    const final = `${rough}.jpeg`
    return final
  }

  const getFirstImg = (article) => {
    const search = article.baseHtml.search('<img')
    const rest = article.baseHtml.slice(search)
    const next = rest.search('src="')
    const rest2 = rest.slice(next)
    const next2 = rest2.search('https')
    const rest3 = rest2.slice(next2)
    const next3 = rest3.search('"')
    const final = rest3.slice(0, next3)
    let newFinal;

    if (final !== '') {
      return (
        <Link to={newTo}>
            <CardMedia
              component="img"
              height="194"
              image={final}
              alt={article.source}
              url={shareUrl}
            />
          </Link>
      )
      
    } else {
      newFinal = getSourceLogo(props.source)
      return (
        <Link to={newTo}>
          <CardMedia
            component="img"
            height='200'
            image={newFinal}
            alt={props.source}
            url={shareUrl}
            sx={{ margin: 'auto', width: '50%', objectFit: 'contain'}}
          />
        </Link>
      )
    }
  }

  return (
    <>
      <div className='cardContainer'>
        <Card sx={{borderRadius: 1.5}}>
        {props.changeCount < 2 ? 
          <Tooltip title='Changes'><Badge color="secondary" id='changeBadgeMainFeed' badgeContent={props.changeCount}><AutoAwesomeMotionIcon /></Badge></Tooltip>
          : <Tooltip title='Changes'><Badge color="warning" id='changeBadgeMainFeed' badgeContent={props.changeCount}><AutoAwesomeMotionIcon /></Badge></Tooltip>}
            <CardHeader className='cardHeader' style={{minHeight: 120}}
              avatar={<Link to={newTo}><Avatar src={getSourceLogo(props.source)} /></Link>}
              title={<Link style={{textDecoration: 'none', color: 'black'}} to={newTo}>{props.title}</Link>}
              titleTypographyProps={{fontSize: '24px', fontWeight: 'bold', width: '90%'}}
              subheader={props.source + ' | ' + formattedDateAndTime(props.datePublished)}
              subheaderTypographyProps={{fontSize: '14px'}}
            />
          {getFirstImg(props)}
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {getCardBody(props)}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <MyButton variant='contained' style={{marginRight: '10px'}}><Link style={{color: 'white', textDecoration: 'none'}} to={newTo}>Read More</Link></MyButton>
            <FacebookShareButton style={{marginRight: '8px'}} url={shareUrl}><FacebookIcon size={32} round={true} /></FacebookShareButton>
            <RedditShareButton style={{marginRight: '8px'}} url={shareUrl}><RedditIcon size={32} round={true} /></RedditShareButton>
            <EmailShareButton url={shareUrl}><EmailIcon size={32} round={true} /></EmailShareButton>
          </CardActions>
        </Card>
      </div>
    </>
  );
}