import React from 'react'
import TopNav from './NavbarComponent'
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button, NavLink, NavItem } from 'reactstrap';
  

function Why() {
    return (
        <>
            <TopNav />
            <br /><br />
            <div className='cardContainer'>
                <Card>
                    <h1 style={{margin: 'auto', marginTop: '16px'}}>Why Jeckit</h1>    
                    <CardText>
                        <div style={{height: 45}}></div>
                        <div className='whyText' style={{width: '75%', margin: 'auto'}}>
                            <p>Jeckit.com    “We just thought you’d wanna know.”  </p>

                            <p>(JEH-kit) v    1. to investigate without or despite emotions     2. to consider the source   3. to mitigate confirmation bias  4.  to leave no stone unturned  Example in a sentence: “Did you jeckit? </p>
                        </div>

                        <div className='whyText' style={{width: '75%', margin: 'auto'}}>
                            <p>
                                Jeckit.com  is an idea born from the wisdom that says, it is important to choose to be informed about important matters.  That choice requires diligence and discipline in considering the sources, the sides and the series of information related to each matter. 
                                History is filled with 20/20 hindsight into tragic developments that might have been significantly different, if good people had better insight into current events. 
                                Jeckit.com  strives to provide neutral tools to promote informed and constructive conversations.     
                                Separate yourself from the emotion driven masses, before you speak, Jeckit. 
                            </p>    
                        </div>
                        <div className='whyText' style={{width: '75%', margin: 'auto'}}>
                            <p>Our vision is to provide a neutral comparative reference tool to assist in viewing changes that are made to news articles after they are published.  </p>
                        </div>
                    </CardText>
                </Card>
            </div>
        </>
    )
}

export default Why;