import React from 'react'
import Box from '@mui/material/Box';
import { FacebookShareButton, FacebookIcon, RedditShareButton, RedditIcon, EmailShareButton, EmailIcon } from 'react-share';

export default function SocialMediaBar() {

    const shareUrl = window.location.href

    return (
        <Box id='searchBar'>
          <FacebookShareButton style={{marginRight: '8px'}} url={shareUrl}><FacebookIcon size={32} round={true} /></FacebookShareButton>
          <RedditShareButton style={{marginRight: '8px'}} url={shareUrl}><RedditIcon size={32} round={true} /></RedditShareButton>
          <EmailShareButton url={shareUrl}><EmailIcon size={32} round={true} /></EmailShareButton>
        </Box>
    )
}