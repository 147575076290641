import * as React from 'react';
import {
    Card, CardText, CardBody,
    CardTitle, CardSubtitle } from 'reactstrap';
import Badge from '@mui/material/Badge'; 
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { Tooltip } from '@mui/material';
import { MyButton } from './SearchComponent';    
import SocialMediaBar from './SocialMediaComponent';
import MobileShare from './MobileShareComponent';
import useMobileDevice from '../hooks/useMobileDevice';
import { CardMedia } from '@mui/material';
import { formattedDateAndTime } from './ArticleComponent';

const getSourceLogo = (source) => {
    const rough = source.replace('www.', '').replace('.com', '')
    const final = `/${rough}.jpeg`
    return final
}

let logo;
let mediaName;
let mediaNameShort;

export default function BuildCard(article) {
    const device = useMobileDevice();
   
    if (article.source !== undefined) {
        logo = getSourceLogo(article.source)
        mediaName = article.source
        mediaNameShort = mediaName.replace('www.', '').replace('.com', '')
    }

    const changedArticles = (article.changes || []).map(change => {
        return <BuildCard key={change.changeNumber} {...change} type="child" />
    })
    const lastPos = article.changeCount - 1;
    document.title = 'Jeckit - ' + article.title.replace('<span class="changed">', '').replace('</span>', '');
    return (
        <>
            <SocialMediaBar />
            {device[0] === true ? <MobileShare title={article.title} /> : <div></div>}
            {article.id ?
            <>
                <div className='cardContainer'>
                    <Card>
                    <Tooltip title='Change Number'><Badge id='changeBadgeDetail' color="warning" badgeContent={article.changes[lastPos].changeNumber}><AutoAwesomeMotionIcon /></Badge></Tooltip>
                        <h1 style={{margin: 'auto', marginTop: '16px'}}>Latest Article</h1>    
                        {/* <CardImg top width="100%" src={logo} alt={mediaNameShort} /> */}
                        <CardMedia
                            component="img"
                            height='150'
                            image={logo}
                            alt={mediaNameShort}
                            sx={{ margin: 'auto', marginTop: '20px', width: '100%', objectFit: 'contain'}}
                        />
                        <CardBody>
                        {article.title.includes('<span class="changed') ? 
                        <CardTitle tag='h5'>{article.title.replace('<span class="changed">', '').replace('</span>', '')}</CardTitle>
                        : <CardTitle tag="h5">{article.title}</CardTitle>}
                        <CardSubtitle tag="h6" id='source' className="mb-2 text-muted">{article.source} | Date Changed: {formattedDateAndTime(article.changes[lastPos].dateOfChange)}</CardSubtitle>
                        <CardText><p dangerouslySetInnerHTML={{ __html: article.changes[lastPos].diffHtml }} /></CardText>
                        <MyButton href={article.url} target="_blank" style={{marginRight: 10}}>Read Article</MyButton>  
                        </CardBody>
                    </Card>
                </div>

                <div className='cardContainer'>
                    <Card>
                        <h1 style={{margin: 'auto', marginTop: '16px'}}>Original Article</h1>
                        {/* <CardImg top width="100%" src={logo} alt={mediaNameShort} /> */}
                        <CardMedia
                            component="img"
                            height='150'
                            image={logo}
                            alt={mediaNameShort}
                            sx={{ margin: 'auto', marginTop: '20px', width: '100%', objectFit: 'contain'}}
                        />
                        <CardBody>
                        <CardTitle tag="h5">{article.title}</CardTitle>
                        <CardSubtitle tag="h6" id='source' className="mb-2 text-muted">{article.source} | {formattedDateAndTime(article.datePublished)}</CardSubtitle>
                        <CardText><p dangerouslySetInnerHTML={{ __html: article.baseHtml }} /></CardText>
                        <MyButton href={article.url} target="_blank" style={{marginRight: 10}}>Read Article</MyButton>  
                        </CardBody>
                    </Card>
                </div>

                <h1 id='historyTag'>Article History</h1>

            </>
            : 
            <>
                <div className='cardContainer' id='change'>
                <Card>
                    {/* <CardImg top width="100%" src={logo} alt={mediaNameShort} /> */}
                    <Tooltip title='Change Number'><Badge id='changeBadgeDetail' color="warning" badgeContent={article.changeNumber}><AutoAwesomeMotionIcon /></Badge></Tooltip>
                    <CardMedia
                            component="img"
                            height='150'
                            image={logo}
                            alt={mediaNameShort}
                            sx={{ margin: 'auto', marginTop: '20px', width: '100%', objectFit: 'contain'}}
                        />
                    <CardBody>
                    {article.title.includes('<span class="changed') ? 
                    <CardTitle tag='h5' id='changedTitle'>{article.title.replace('<span class="changed">', '').replace('</span>', '')}</CardTitle>
                    : <CardTitle tag="h5">{article.title}</CardTitle>}
                    <CardSubtitle tag="h6" className="mb-2 text-muted">{mediaName} | Date Changed: {formattedDateAndTime(article.dateOfChange)}</CardSubtitle>
                    <CardText><p dangerouslySetInnerHTML={{ __html: article.diffHtml }} /></CardText>
                    <MyButton href={article.url} target="_blank" style={{marginRight: 10}}>Read Article</MyButton>  
                    {device ? '' : <MobileShare title={article.title} />}
                    </CardBody>
                </Card>
                </div>     
            </>
            }   
        {changedArticles}
        </>
    )
}
