import React from 'react';
import Main from './components/MainComponent';
import ArticleDetail from './components/ArticleDetail';
import Why from './components/WhyComponent';
import How from './components/HowComponent';
import About from './components/AboutComponent';
import MobileSearch from './components/MobileSearchComponent';
import useMobileDevice from './hooks/useMobileDevice';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

export default function App() {

    const device = useMobileDevice();

    return (
        <>
            {/* <Main /> */}
            <BrowserRouter>
                <Switch>
                    <Route path="/" component={Main} exact />
                    <Route path="/Article" component={ArticleDetail} />
                    <Route path="/Why" component={Why} />
                    <Route path="/How" component={How} />
                    <Route path="/About" component={About} />
                </Switch>
            
            </BrowserRouter>
            {device ? '' : <MobileSearch />}
        </>
    )
}