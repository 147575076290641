import React, { Component } from 'react';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button, NavLink, NavItem } from 'reactstrap';
import TopNav from './NavbarComponent';
import Badge from '@mui/material/Badge'; 
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { Tooltip } from '@mui/material';
import axios from 'axios';
import { MyButton } from './SearchComponent';
import SocialMediaBar from './SocialMediaComponent';

export default function AboutComponent(props) {

    return (
        <>
        <TopNav />
        <br /><br />
        <div className='cardContainer'>
            <Card>
                <h1 style={{margin: 'auto', marginTop: '16px'}}>About Jeckit</h1>    
                {/* <CardImg top width="100%" src="/assets/318x180.svg" alt="Card image cap" /> */}
                {/* <Tooltip title='Change Number'><Badge color="warning" badgeContent={article.changes[lastPos].changeNumber} style={{marginTop: '-10px', width: '25px', marginLeft: '93%'}}><AutoAwesomeMotionIcon /></Badge></Tooltip> */}
                <CardBody>
                <CardTitle tag="h5"></CardTitle>
                <CardSubtitle tag="h6" className="mb-2 text-muted"></CardSubtitle>
                <CardText>
                    <div className='whyText' style={{width: '75%', margin: 'auto'}}>
                        <p>“Every record has been destroyed or falsified, every book rewritten, every picture has been repainted, every statue and street building has been renamed, every date has been altered. And the process is continuing day by day and minute by minute. History has stopped. Nothing exists except an endless present in which the Party is always right.” </p>

                        <p>When George Orwell wrote 1984 he couldn’t imagine how the computer would change the way we receive information.  Winston, the protagonist in the story, was a clerk in the Records Department of the Ministry of Truth.  He constantly changed articles written to match the current party line.  This is what currently happens to news stories today.   </p>

                        <p>While some news publications clearly mark the story as edited, it's impossible to know exactly what was edited and the reader must take the source at its word.  That makes a tedious trust relationship between reader and journalist. Jeckit was created to bridge the gap of trust as an impartial broker of truth.  A “Winston” could very easily manipulate a story to fit the narrative, ensuring the publisher is politically correct and in-step with mainstream thinking. </p>

                        <p>Here at Jeckit we believe that the media is changing news stories without notating exactly what was changed in order to meet a narrative to conform to the “mainstream thinking”.  Often, they are unable to determine what the “right way” of thinking is, so they go back and edit the story to match what the correct thought was.  With Jeckit, the media will be held accountable by having the original story stored in our database for public viewing.  </p>

                        <p>Our goal is to allow people to easily trace edits and changes to the story to prevent the “Winstons” of the world from being the thought police.  Everyone deserves the ability to see the facts as presented at a certain time and we are providing a tool to allow exactly that.   </p>

                        <p>As our database of articles grow, we hope to find and highlight hypocrisy from all sources.   </p>
                    </div>
                </CardText>
                {/* <MyButton href={article.url} target="_blank">Read Article</MyButton>   */}
                </CardBody>
            </Card>
            
            
        </div>
        </>
    );
}