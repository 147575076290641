import React, { Component } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const MyButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#a93b44'),
    backgroundColor: '#a93b44',
    '&:hover': {
      backgroundColor: '#a93b44',
    },
  }));

export default class Search extends Component {

    render() {

        const SearchBar = styled(TextField)({
            '& label.Mui-focused': {
                color: '#a93b44',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#a93b44',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                borderColor: '#a93b44',
                },
                '&:hover fieldset': {
                borderColor: '#a93b44',
                },
                '&.Mui-focused fieldset': {
                borderColor: '#a93b44',
                },
            },
        });

        return (
            <>
                <Box component='form' noValidate autoComplete='off' id='searchBar' onSubmit={(e) => this.props.onClick(e)}>
                    <SearchBar id='searchInput' label='Articles' />
                    <MyButton id='searchSubmit' variant='contained' onClick={this.props.onClick}>Search</MyButton>
                </Box>
            </>
        )
    }
}