import React from 'react'
import ShareIcon from '@mui/icons-material/Share';

const MobileShare = ({ title }) => {
    const handleOnClick = () => {
        try {
            if (navigator.share) {
                navigator
                    .share({
                    title: `JeckIt | ${title}`,
                    text: `Check out ${title} on JeckIt`,
                    url: document.location.href,
                    })
                    .then(() => {
                    console.log('Successfully shared');
                    })
                    .catch(error => {
                    console.error('Something went wrong sharing the blog', error);
                    });
                }
        } catch(error) {
            console.log(error)
        }
    };
  
    return (
      <div className='share-icon' onClick={handleOnClick}>
        <ShareIcon />
      </div>
    );
  };

export default MobileShare;